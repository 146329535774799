export const termAndConditions = {
  policyTitle: "Terms and Conditions",
  description:
    "Welcome to Skill Surf! These Terms and Conditions govern your use of our online courses and services. By enrolling in any of our courses or using our website, you agree to abide by the following terms.",
  subContent: [
    {
      policySubTitle: "a. Acceptance of Terms",
      policyDescription: [
        {
          description:
            "By accessing or using our platform, you confirm that you have read, understood, and agree to be bound by these Terms and Conditions.",
        },
      ],
    },
    {
      policySubTitle: "b. Registration and Account Security",
      policyDescription: [
        {
          description: "Users must register to access courses.",
        },
        {
          description:
            "You are responsible for keeping your login details secure.",
        },
        {
          description:
            "Any unauthorized use of your account should be reported immediately.",
        },
      ],
    },
    {
      policySubTitle: "c. Course Enrollment",
      policyDescription: [
        {
          description:
            "Once enrolled in a course, you gain access to its content for a period of one year.",
        },
        {
          description: "Sharing course access or content is prohibited.",
        },
      ],
    },
    {
      policySubTitle: "d. Fees and Payment",
      policyDescription: [
        {
          description:
            "Course fees are stated on our website and must be paid in full to access the course.",
        },
        {
          description:
            "Payments are non-refundable except as provided in our Refund Policy.",
        },
      ],
    },
    {
      policySubTitle: "e. Intellectual Property",
      policyDescription: [
        {
          description:
            "All course content, including texts, videos, graphics, and logos, are the property of Skill Surf and are protected by copyright laws. Unauthorized use, distribution, or reproduction is prohibited.",
        },
      ],
    },
    {
      policySubTitle: "f. User Conduct",
      policyDescription: [
        {
          description: "Users are expected to:",
          subDescription: [
            {
              description: "Respect other students and instructors.",
            },
            {
              description:
                "Do not engage in cheating, plagiarizing, or sharing exam answers.",
            },
            {
              description:
                "Do not use the platform for unauthorized or illegal activities.",
            },
          ],
        },
      ],
    },
    {
      policySubTitle: "g. Termination",
      policyDescription: [
        {
          description:
            "We reserve the right to terminate or suspend access to our courses and platform for users who violate these terms or engage in misconduct.",
        },
      ],
    },
    {
      policySubTitle: "h. Disclaimers",
      policyDescription: [
        {
          description: `Our courses are provided "as is" without warranties of any kind, either express or implied. We do not guarantee that course content will meet your expectations or requirements.`,
        },
      ],
    },
    {
      policySubTitle: "i. Limitation of Liability",
      policyDescription: [
        {
          description:
            "To the fullest extent permitted by law, Skill Surf shall not be liable for any indirect, incidental, or consequential damages resulting from the use or inability to use our services.",
        },
      ],
    },
    {
      policySubTitle: "j. Changes to These Terms",
      policyDescription: [
        {
          description:
            "We may update or modify these terms periodically. Your continued use after such changes signifies your acceptance of the new terms.",
        },
      ],
    },
    {
      policySubTitle: "k. Governing Law",
      policyDescription: [
        {
          description:
            "These terms shall be governed by the laws of Sri Lanka.",
        },
      ],
    },
    {
      policySubTitle: "l. Dispute Resolution",
      policyDescription: [
        {
          description:
            "Any disputes arising out of these terms will be resolved through mediation. If a solution isn't reached, the dispute may be taken to a competent court in Sri Lanka.",
        },
      ],
    },
    {
      policySubTitle: "m. Contact Us",
      policyDescription: [
        {
          description: `For questions or concerns regarding these Terms and Conditions, please contact our support team via <a
            href="mailto: carelfinances@gmail.com"
          >
          carelfinances@gmail.com.
          </a>`,
        },
      ],
    },
  ],
  active: "terms-and-conditions",
};
