import "./Spinner.scss";
import loading_icon from "./assets/loading.gif";
import Modal from 'react-bootstrap/Modal';
interface SpinnerData {
    text?: string,
    showSpinner?: boolean
}

const Spinner = (props: SpinnerData) => {
    return (
        <>
            <Modal show={props.showSpinner} centered>
                <Modal.Body>
                    <div className="loading-wrapper">
                        <img src={loading_icon} alt="loading icon" width="100px" />
                        <p className="fs-5 pt-3">Loading... Please Wait</p>
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )

}


export default Spinner;