import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import NavBar from "../../shared/components/NavBar/NavBar";
import Footer from "../../shared/components/Footer/Footer";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import "./PaymentGateway.scss";
import Spinner from "../../shared/components/Spinner/Spinner";
import { firestore } from "../../shared/config/firebase";
import { doc, getDoc } from "@firebase/firestore";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { courses } from "../../shared/utils/utils";

interface ICountry {
  country: string;
  dialCode: string;
}

const PaymentGateway = () => {
  const navigate = useNavigate();

  const [countries, setCountries] = useState<ICountry[]>([]);
  const [showSpinner, setShowSpinner] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [isInvalidFirstName, setIsInvalidFirstName] = useState(false);
  const [invalidFirstName, setInvalidFirstName] = useState("");

  const [lastName, setLastName] = useState("");
  const [isInvalidLastName, setIsInvalidLastName] = useState(false);
  const [invalidLastName, setInvalidLastName] = useState("");

  const [email, setEmail] = useState("");
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState("");

  const [mobile, setMobile] = useState("");
  const [isInvalidMobile, setIsInvalidMobile] = useState(false);
  const [invalidMobile, setInvalidMobile] = useState("");

  const [addressOne, setAddressOne] = useState("");
  const [isInvalidAddressOne, setIsInvalidAddressOne] = useState(false);
  const [invalidAddressOne, setInvalidAddressOne] = useState("");

  const [addressTwo, setAddressTwo] = useState("");

  const [city, setCity] = useState("");
  const [isInvalidCity, setIsInvalidCity] = useState(false);
  const [invalidCity, setInvalidCity] = useState("");

  const [country, setCountry] = useState("");
  const [isInvalidCountry, setIsInvalidCountry] = useState(false);
  const [invalidCountry, setInvalidCountry] = useState("");

  const [amount, setAmount] = useState("");
  const [isInvalidAmount, setIsInvalidAmount] = useState(false);
  const [invalidAmount, setInvalidAmount] = useState("");

  const [course, setCourse] = useState("");
  const [isInvalidCourse, setIsInvalidCourse] = useState(false);
  const [invalidCourse, setInvalidCourse] = useState("");

  const [remarks, setRemarks] = useState("");
  const [isInvalidRemarks, setIsInvalidRemarks] = useState(false);
  const [invalidRemarks, setInvalidRemarks] = useState("");

  useEffect(() => {
    axios
      .get(
        "https://us-central1-skill-surf.cloudfunctions.net/api/countries_with_dial_codes"
      )
      .then((response) => {
        setCountries(response.data);
      })
      .catch(() => {
        setCountries([]);
      });
  }, []);

  const validate = () => {
    let isValid = true;

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const mobileNumberRegex = /^\d+$/;

    const amountRegex = /^\$?[0-9]?((\.[0-9]+)|([0-9]+(\.[0-9]+)?))$/;

    if (!firstName || firstName.length < 3) {
      setIsInvalidFirstName(true);
      if (firstName) {
        setInvalidFirstName(
          "Your first name should contain at least 3 characters"
        );
      } else {
        setInvalidFirstName("Your first name is required");
      }
      isValid = false;
    } else {
      setIsInvalidFirstName(false);
      setInvalidFirstName("");
    }

    if (!lastName || lastName.length < 3) {
      setIsInvalidLastName(true);
      if (lastName) {
        setInvalidLastName(
          "Your last name should contain at least 3 characters"
        );
      } else {
        setInvalidLastName("Your last name is required");
      }
      isValid = false;
    } else {
      setIsInvalidLastName(false);
      setInvalidLastName("");
    }

    if (!email || !emailRegex.test(email)) {
      setIsInvalidEmail(true);
      if (email) {
        setInvalidEmail("Your email is invalid");
      } else {
        setInvalidEmail("Your email is required");
      }
      isValid = false;
    } else {
      setIsInvalidEmail(false);
      setInvalidEmail("");
    }

    if (!mobile || !mobileNumberRegex.test(mobile)) {
      setIsInvalidMobile(true);
      if (mobile) {
        setInvalidMobile("Your mobile number is invalid");
      } else {
        setInvalidMobile("Your mobile number is required");
      }
      isValid = false;
    } else {
      setIsInvalidMobile(false);
      setInvalidMobile("");
    }

    if (!addressOne) {
      setIsInvalidAddressOne(true);
      setInvalidAddressOne("Your address is required");
      isValid = false;
    } else {
      setIsInvalidAddressOne(false);
      setInvalidAddressOne("");
    }

    if (!city) {
      setIsInvalidCity(true);
      setInvalidCity("Your city is required");
      isValid = false;
    } else {
      setIsInvalidCity(false);
      setInvalidCity("");
    }

    const tempCountry = countries.find(
      (countryItem) => countryItem.country === country
    );

    if (!country || !tempCountry) {
      setIsInvalidCountry(true);
      if (country) {
        setInvalidCountry("Please select a valid country");
      } else {
        setInvalidCountry("Your country is required");
      }
      isValid = false;
    } else {
      setIsInvalidCountry(false);
      setInvalidCountry("");
    }

    if (!amount || !amountRegex.test(amount)) {
      setIsInvalidAmount(true);
      if (amount) {
        setInvalidAmount("Please enter a valid amount");
      } else {
        setInvalidAmount("Amount is required");
      }
      isValid = false;
    } else {
      setIsInvalidAmount(false);
      setInvalidAmount("");
    }

    if (!course || !courses.find((courseItem) => courseItem.value === course)) {
      setIsInvalidCourse(true);
      if (course) {
        setInvalidCourse("Please select a valid course");
      } else {
        setInvalidCourse("Course is required");
      }
      isValid = false;
    } else {
      setIsInvalidCourse(false);
      setInvalidCourse("");
    }

    if (course === "Other" && !remarks) {
      setIsInvalidRemarks(true);
      setInvalidRemarks("Remarks is required");
      isValid = false;
    } else {
      setIsInvalidRemarks(false);
      setInvalidRemarks("");
    }

    return isValid;
  };

  const fetchPaymentData = async (orderId: string) => {
    const docRef = doc(firestore, "Payments", orderId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      navigate("/payment-details", {
        state: {
          ...docSnap.data(),
          amount: Number(docSnap.data().amount),
          createdAt: moment(docSnap.data().createdAt.seconds * 1000).format(
            "MMMM DD, YYYY HH:mm:ss"
          ),
        },
      });
    } else {
      console.log("No such document!");
    }
  };

  // @ts-ignore
  window.payhere.onCompleted = (orderId) => {
    fetchPaymentData(orderId);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const proceedToPaymentButton: any = document.getElementById(
      "proceed-to-payment-button"
    );

    if (validate()) {
      setShowSpinner(true);
      const url =
        "https://us-central1-skill-surf.cloudfunctions.net/api/skill_surf_payment_data";
      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          setShowSpinner(false);
          console.log("Success:", data);
          proceedToPaymentButton.disabled = true;

          setFirstName("");
          setLastName("");
          setEmail("");
          setMobile("");
          setAddressOne("");
          setAddressTwo("");
          setCity("");
          setCountry("");
          setAmount("");
          setCourse("");
          setRemarks("");
          // @ts-ignore
          window.payhere.startPayment(data);

          let countdownNum = 60;
          incTimer();

          function incTimer() {
            setTimeout(function () {
              if (countdownNum !== 0) {
                countdownNum--;
                proceedToPaymentButton.textContent =
                  "Proceed to payment: " + countdownNum;
                incTimer();
              } else {
                proceedToPaymentButton.textContent = "Proceed to payment";
                proceedToPaymentButton.disabled = false;
              }
            }, 1000);
          }
        })
        .catch((error) => {
          setShowSpinner(false);
          console.error("Error:", error);
        });
    }
  };

  return (
    <div className="bg-background fade-in">
      <NavBar background="bg-background" />
      <section className="container payment-gateway-section d-flex flex-column justify-content-center my-5">
        <Form onSubmit={handleSubmit} noValidate>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="first-name">
                  First Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  id="first-name"
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  value={firstName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFirstName(e.target.value);
                  }}
                />
                {isInvalidFirstName ? (
                  <p className="text-danger">{invalidFirstName}</p>
                ) : (
                  <></>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="last-name">
                  Last Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  id="last-name"
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  value={lastName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setLastName(e.target.value);
                  }}
                />
                {isInvalidLastName ? (
                  <p className="text-danger">{invalidLastName}</p>
                ) : (
                  <></>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="email">
                  Email <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  id="email"
                  type="email"
                  placeholder="Your Email"
                  name="email"
                  value={email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(e.target.value);
                  }}
                />
                {isInvalidEmail ? (
                  <p className="text-danger">{invalidEmail}</p>
                ) : (
                  <></>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="mobile">
                  Mobile Number <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  id="mobile"
                  type="tel"
                  placeholder="Mobile Number"
                  name="mobile"
                  value={mobile}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setMobile(e.target.value);
                  }}
                />
                {isInvalidMobile ? (
                  <p className="text-danger">{invalidMobile}</p>
                ) : (
                  <></>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="address-one">
                  Address line 1 <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  id="address-one"
                  type="text"
                  placeholder="Address Line 1"
                  name="addressLineOne"
                  value={addressOne}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setAddressOne(e.target.value);
                  }}
                />
                {isInvalidAddressOne ? (
                  <p className="text-danger">{invalidAddressOne}</p>
                ) : (
                  <></>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="address-two">Address line 2</Form.Label>
                <Form.Control
                  id="address-two"
                  type="text"
                  placeholder="Address Line 2"
                  name="addressLineTwo"
                  value={addressTwo}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setAddressTwo(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="city">
                  City <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  id="city"
                  type="text"
                  placeholder="City"
                  name="city"
                  value={city}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setCity(e.target.value);
                  }}
                />
                {isInvalidCity ? (
                  <p className="text-danger">{invalidCity}</p>
                ) : (
                  <></>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="country">
                  Country <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  id="country"
                  value={country}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setCountry(e.target.value);
                  }}
                  name="country"
                >
                  <option disabled value={""}>
                    Select a country
                  </option>
                  {countries.map((countryItem) => {
                    return (
                      <option key={uuidv4()} value={countryItem.country}>
                        {countryItem.country}
                      </option>
                    );
                  })}
                </Form.Select>
                {isInvalidCountry ? (
                  <p className="text-danger">{invalidCountry}</p>
                ) : (
                  <></>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="amount">
                  Amount <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  id="amount"
                  type="number"
                  placeholder="amount"
                  name="amount"
                  value={amount}
                  onKeyDown={(e) => {
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setAmount(e.target.value);
                  }}
                />
                {isInvalidAmount ? (
                  <p className="text-danger">{invalidAmount}</p>
                ) : (
                  <></>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="course">
                  Course <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  id="course"
                  value={course}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setCourse(e.target.value);
                  }}
                  name="course"
                >
                  <option disabled value={""}>
                    Select a course
                  </option>
                  {courses.map((course) => {
                    return (
                      <option key={uuidv4()} value={course.value}>
                        {course.label}
                      </option>
                    );
                  })}
                </Form.Select>
                {isInvalidCourse ? (
                  <p className="text-danger">{invalidCourse}</p>
                ) : (
                  <></>
                )}
              </Form.Group>
            </Col>
          </Row>
          {course === "Other" && (
            <Row>
              <Col md={6}>
                <Form.Group className="mb-4">
                  <Form.Label htmlFor="remarks">
                    Remarks <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    id="remarks"
                    type="text"
                    placeholder="Remarks"
                    name="remarks"
                    value={remarks}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setRemarks(e.target.value);
                    }}
                  />
                  {isInvalidRemarks ? (
                    <p className="text-danger">{invalidRemarks}</p>
                  ) : (
                    <></>
                  )}
                </Form.Group>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Button
                className="send-btn font-family-class fs-6 text-white"
                variant="none"
                type="submit"
                style={{ fontWeight: 500 }}
                id="proceed-to-payment-button"
              >
                Proceed to payment
              </Button>
            </Col>
          </Row>
        </Form>
      </section>
      <Footer />
      <Spinner showSpinner={showSpinner} />
    </div>
  );
};

export default PaymentGateway;
