import React from "react";
import { Button } from "react-bootstrap";
import "./PrimaryButton.scss";

interface Iprops {
  onClick: () => void;
  classes?: string;
  name: string;
  type?: "submit" | "reset" | "button";
}

const PrimaryButton = (props: Iprops) => {
  return (
    <div className="primary-button-container">
      <Button
        className={`primary-button fs-5 ${props.classes}`}
        type={props.type}
        onClick={props.onClick}
      >
        {props.name}
      </Button>{" "}
    </div>
  );
};

export default PrimaryButton;
