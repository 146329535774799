import React from "react";
import { Link } from "react-router-dom";
import "./SecondaryLink.scss";

interface Iprops {
  to: string;
  classes?: string;
  name: string;
}

const SecondaryLink = (props: Iprops) => {
  return (
    <div className="secondary-link-container text-center mt-4 mt-lg-0">
      <Link className={`secondary-button fs-5 ${props.classes}`} to={props.to}>
        {props.name}
      </Link>{" "}
    </div>
  );
};

export default SecondaryLink;
