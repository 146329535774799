import React from "react";
import "./JoinTheTeam.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavBar from "../../shared/components/NavBar/NavBar";
import Footer from "../../shared/components/Footer/Footer";
import Commitment_to_the_Cause from "./assets/commitment_to_the_cause.webp";
import Excellent_Communication_Skills from "./assets/excellent_communication_skills.webp";
import Strong_Technical_Background from "./assets/strong_technical_background.webp";
import carrer_image from "./assets/career_1.webp";
import join_flyer from "./assets/join-flyer.webp";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";

const JoinTheTeam: React.FC = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="fade-in">
      <div className="containr-fluid bg-background">
        <NavBar active="join-the-team" />
      </div>

      {/*  <div className="section-dark container-fluid ">
        <div className="d-flex justify-content-around">
          <div className="row row-cols-lg-auto g-3 align-items-center justify-content-center ">
            <div className="col-md-12 text-center">
              <h3 className=" fs-3 text-white text-center">Join the Team</h3>
            </div>
          </div>
        </div>
      </div> */}
      <div className="containr-fluid bg-background py-5">
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton></Modal.Header>
          <img alt="flyer" src={join_flyer} />
        </Modal>

        <div className="container">
          <Row className="d-flex align-items-center justify-content-center">
            <Col className="col-md-6 align-items-center col-12 text-align">
              <h2
                className="font-family-class text-secondary  fs-1 mt-5 "
                style={{ fontWeight: 500 }}
              >
                Join the Team
              </h2>
              <h2
                className="font-family-class text-secondary  fs-3  mb-3"
                style={{ fontWeight: 400 }}
              >
                Shape your career with the Skill Surf team!
              </h2>
              <p className="text-gray join-team-para">
                We at Skill Surf are reinventing how educational content is
                delivered! Courses are outcome-oriented, combining strong
                fundamentals and hands-on experience.
              </p>
              <p className="text-gray join-team-para">
                We started by organising free workshops conducted by a group of
                undergraduates, fresh graduates, and lecturers at ENTC. We are
                currently conducting several courses related to the main focus
                areas of the Internet of Things, Computer Architecture, and
                Cybersecurity.
              </p>
              <p className="text-gray join-team-para">
                Now that we look forward to expanding our scope, we need highly
                skilled lecturers/instructors on our team who have a passion for
                teaching and help us empower Sri Lankans to be industry and life
                ready!
              </p>
            </Col>
            <Col className="col-md-6 col-12">
              <img
                src={carrer_image}
                width="100%"
                alt="Vector representing career with Skill Surf"
              />
            </Col>
          </Row>
        </div>
      </div>
      <div className="container-fluid section-dark">
        <div className="container ">
          <Row className="align-items-center  d-flex justify-content-between">
            <Col className="col-12 col-md-6 col-lg-9">
              <p className="text-white font-family-class fs-5 mb-1 text-center text-md-start">
                If you share the same passion, please fill this Google Form and
                apply to be a part{" "}
                <span className="text-nowrap">of our team!</span>
              </p>
            </Col>
            <Col className="col-12 col-md-6 col-lg-3 mt-3 mt-md-0 d-flex d-md-block justify-content-center">
              <a
                href="https://forms.gle/SYj4TjG6H8Lb2gnz6"
                target={"_blank"}
                rel="noreferrer"
              >
                <button className="secondary-button text-center mt-4 mt-lg-0 fs-5">
                  Google Form
                </button>
              </a>
            </Col>
          </Row>
        </div>
      </div>

      <div className="container py-2 mt-4 ">
        <h2
          className="font-family-class text-gray text-center  fs-4 my-4 mb-5 mt-5"
          style={{ fontWeight: 450 }}
        >
          Main Expectations from Skill Surf Lecturers
        </h2>
        <Row className="mt-4 mt-sm-4 mt-md-4 mt-lg-4 justify-content-between mx-1 py-3 pb-5">
          <Col className="col-12 col-sm-6 col-md-6 col-lg-3 ">
            <div>
              <img
                src={Strong_Technical_Background}
                alt="Vector representing Strong Technical Background"
                width={80}
              />
            </div>
            <h5 className="font-family-class text-gray fs-5  mt-2">
              Strong Technical Background
            </h5>
            <p className="font-family-class text-light-gray fs-6">
              A deep understanding of the subject matter, together with strong
              fundamentals and
              <span className="text-nowrap"> hands-on experience.</span>
            </p>
          </Col>

          <Col className="col-12 col-sm-6 col-md-6 col-lg-3 ">
            <div>
              <img
                src={Excellent_Communication_Skills}
                alt="Vector representing Excellent Communication Skills"
                width={80}
              />
            </div>
            <h5 className="font-family-class text-gray fs-5  mt-2">
              Excellent Communication Skills
            </h5>
            <p className="font-family-class text-light-gray fs-6">
              Ability to clearly and effectively communicate the subject matters
              to students and inspire students
              <span className="text-nowrap"> to learn.</span>
            </p>
          </Col>

          <Col className="col-12 col-sm-6 col-md-6 col-lg-3 ">
            <div>
              <img
                src={Commitment_to_the_Cause}
                alt="Vector representing Commitment to the Cause"
                width={80}
              />
            </div>
            <h5 className="font-family-class text-gray fs-5  mt-2">
              Commitment to the Cause
            </h5>
            <p className="font-family-class text-light-gray fs-6">
              Pay attention to detail to deliver the best outcome and ensure
              student expectations
              <span className="text-nowrap"> are exceeded.</span>
            </p>
          </Col>
        </Row>
      </div>

      <div className="container-fluid bg-background py-5 mt-5">
        <div className="container ">
          <Row className="align-items-center d-flex justify-content-center">
            <Col className="col-12 col-md-6 col-lg-5 d-flex justify-content-center">
              <p className="text-gray font-family-class fs-5 mb-1 text-center text-md-start">
                View the promotional flyer for more details
              </p>
            </Col>
            <Col
              className="col-12 col-md-6 col-lg-2 mt-3 mt-md-0 d-flex 
            justify-content-center"
            >
              <button
                className="register-button text-center w-100
               fs-5 py-2 px-3 rounded text-white mx-2"
                onClick={handleShow}
              >
                {" "}
                View Flyer{" "}
              </button>
            </Col>
          </Row>
        </div>
      </div>
      <div className="container">
        <p className="text-gray text-center mt-5 fs-6">
          For any questions/clarifications, reach out to us through our email
          &nbsp;
          <a href="mailto:skillsurfedu@gmail.com">skillsurfedu@gmail.com</a> or
          social media.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default JoinTheTeam;
