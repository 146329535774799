import introductionToQuantumComputingImage from "../../components/ProgramCatalog/assets/introduction-to-quantum-computing.webp";
import QuatumImage from "../../components/CoursePage/assets/quantum_lab.webp";
import entcImage from "../../components/CoursePage/assets/entc-logo.webp";
import ieeeImage from "../../components/CoursePage/assets/ieee.webp";
import hariniHapuarachchi from "../../components/CoursePage/assets/instructor-img/Harini Hapuarachchi.webp";
import raviWijesekara from "../../components/CoursePage/assets/instructor-img/Ravi.webp";
import theshaniNuradha from "../../components/CoursePage/assets/instructor-img/Theshani.webp";
import kithminWickremasinghe from "../../components/CoursePage/assets/instructor-img/Kithmin Wickremasinghe.webp";
import quantumFlyer from "../../components/CoursePage/assets/IQC workshop.webp";
import { ECourseStatus, ICourse } from "../utils/utils";

export const introductionToQuantumComputing: ICourse = {
  header: {
    courseImage: introductionToQuantumComputingImage,
    programCategory: "Workshop",
    difficultyLevel: "Beginner",
    courseTitle: "Introduction to Quantum Computing",
    specialization: "Free Workshop",
    courseDescription:
      'Look beyond the world of classical computers. Understand the "why" and "how" behind a cutting-edge domain with many interesting applications. Let’s start exploring the world of quantum computing!',
    registerButton: "Registration Closed!",
    registerLink: "",
    secondaryName: "One Day Online Workshop",
  },
  headerSecondSection: {
    starCount: 0,
    reviewCount: 0,
    registrationDeadline: "19 January 2023",
    parameterOne: "Workshop Date:",
    parameterTwo: "21 January 2023",
    parameterThree: "New Workshop",
    collaborationLogos: [
      {
        collaborationLogo: QuatumImage,
        collaborationLogosName: "Quantum Logo",
      },
      {
        collaborationLogo: entcImage,
        collaborationLogosName: "ENTC Logo",
      },
      {
        collaborationLogo: ieeeImage,
        collaborationLogosName: "IEEE Logo",
      },
    ],
  },
  courseOutline: [
    {
      session: "Session 1",
      sessionContent: [
        {
          heading: "Welcome by Dr. Ranga Rodrigo",
          description: "",
          subContent: [],
        },
        {
          heading: "A brief history of Skill Surf by Dr. Subodha Charles",
          description: "",
          subContent: [],
        },
        {
          heading:
            "Keynote Speech: A glimpse into the quantum realm by Dr. Hirini Hapuarachchi",
          description: "",
          subContent: [],
        },
        {
          heading:
            "A gentle introduction to quantum information and computing by Kithmin Wickremasinghe",
          description: "",
          subContent: [],
        },
        {
          heading:
            "Why do quantum computers work the way they do? by Dr. Ravi Wijesekara",
          description: "",
          subContent: [
            {
              heading: "",
              description:
                "Demonstration of Quantum principles using Polarization of Light",
            },
            {
              heading: "",
              description:
                "Qubits and Mathematical background behind Quantum Computing",
            },
          ],
        },
        {
          heading:
            "The journey of a quantum algorithm by kithmin Wickremasinghe",
          description: "",
          subContent: [
            {
              heading: "",
              description: "Quantum Gates and Circuits",
            },
          ],
        },
      ],
    },
    {
      session: "Session 2",
      sessionContent: [
        {
          heading: "QISKIT based Hands-On notebook session by Theshani Nuradha",
          description: "",
          subContent: [
            {
              heading: "",
              description: "First-Circuit - Quantum Gates and Circuits",
            },
            {
              heading: "",
              description:
                "Solving the Deutsch oracle problem: the simplest problem where a quantum computer outperforms classical methods",
            },
            {
              heading: "",
              description:
                "Quantum entanglement and Teleportation: Nobel prize in Physics 2022",
            },
          ],
        },
      ],
    },
  ],
  faqs: [],
  experts: [
    {
      image: hariniHapuarachchi,
      name: "Dr. Harini Hapuarachchi",
      designation: "Postdoctoral Research Fellow at RMIT University, Australia",
    },
    {
      image: kithminWickremasinghe,
      name: "Mr. Kithmin Wickremasinghe",
      designation: "MASc Student in Electrical and Computer Eng., UBC, Canada",
    },
    {
      image: raviWijesekara,
      name: "Dr. Ravi Wijesekara",
      designation:
        "PhD Student in Quantum Physics and Nanotechnology, Monash University,  Australia",
    },
    {
      image: theshaniNuradha,
      name: "Ms. Theshani Nuradha",
      designation:
        "PhD Student in Electrical and Computer Eng.,  Cornell University, USA",
    },
  ],
  studentReviews: [
    "On the 1st day, when I saw the registration details, I went through the course curriculum and I joined the course with an expectation. The team fulfilled my expectations 100%.",
    "The course is very interesting and provides a good knowledge in the intended learning area. I would like to see more courses like this in future.",
    "This was a great course. Although the instructors couldn't touch all the aspects, guiding the students on what to follow and refer to is a great thing. Grateful for the organizers and instructors sharing their knowledge. Hope to join the next courses as well.",
    "The personal ML/embedded projects that the instructors shared as case studies were really inspiring. Sharing the ups and downs and what to expect along this path is a real motivator!",
    "Flow of the content was really great. It will really help to beginners who are entering to ML & Edge computing.",
  ],
  facts: [],
  classSchedule: [
    "Morning session: 9:00 AM - 12:00 PM",
    "Afternoon session: 1:00 PM - 4:00 PM",
  ],
  prerequisite: "No prior experience in Quantum Computing is required.",
  isCourseFlyer: true,
  flyer: {
    description:
      "Learn more about Quantum Computing workshop from the workshop announcement flyer",
    button: "View Flyer",
    image: quantumFlyer,
  },
  courseStatus: ECourseStatus.OLD,
  showTimer: false,
  isDifferentBullet: true,
  isResources: true,
  resourcesYoutubeLink:"https://www.youtube.com/@skillsurfedu",
  resourcesGitHubLink:"https://github.com/SkillSurf/introduction_qc"
};
