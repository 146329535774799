import React from "react";
import { IPolicy } from "../../shared/utils/utils";
import NavBar from "../../shared/components/NavBar/NavBar";
import { Col, Row } from "react-bootstrap";
import parse from "html-react-parser";
import Footer from "../../shared/components/Footer/Footer";
import "./PolicyPage.scss";

interface IProps {
  policy: IPolicy;
}

const RefundPolicy = (props: IProps) => {
  return (
    <div className="fade-in">
      <NavBar background="bg-background" />
      <section className="policy-container mt-5">
        <Row className="policy-title font-family-class">
          <Col>{props.policy.policyTitle}</Col>
        </Row>
        <Row className="description text-gray font-family-class mt-2">
          <Col>{props.policy.description}</Col>
        </Row>
        <Row className="mt-4">
          {props.policy.subContent.map((c, index) => {
            return (
              <Col key={index} xs={12}>
                <Row className="sub-title text-gray font-family-class">
                  <Col>{c.policySubTitle}</Col>
                </Row>
                <Row className="sub-description font-family-class">
                  <Col className="ps-0">
                    <ul>
                      {c.policyDescription.map((subContent, index) => {
                        return (
                          <li key={index}>
                            {parse(subContent.description)}
                            <ul>
                              {subContent.subDescription &&
                                subContent.subDescription.map((d) => {
                                  return <li>{d.description}</li>;
                                })}
                            </ul>
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      </section>
      <Footer active={props.policy.active} isFinancialInquiries={true} />
    </div>
  );
};

export default RefundPolicy;
