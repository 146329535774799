import React, { useState } from "react";
import "./ContactUs.scss";
import { Button, Col, Row } from "react-bootstrap";
import Chat from "./assets/image-contact.webp";
import Form from "react-bootstrap/Form";
import NavBar from "../../shared/components/NavBar/NavBar";
import Footer from "../../shared/components/Footer/Footer";
import Spinner from "../../shared/components/Spinner/Spinner";
import Modal from "react-bootstrap/Modal";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isInvalidName, setIsInvalidName] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isInvalidMessage, setIsInvalidMessage] = useState(false);
  const [invalidName, setInvalidName] = useState("");
  const [invalidEmail, setInvalidEmail] = useState("");
  const [invalidMessage, setInvalidMessage] = useState("");
  const [show, setShow] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const handleClose = () => setShow(false);

  const validate = () => {
    let isValid = true;
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!name || name.length < 3) {
      setIsInvalidName(true);
      if (name) {
        setInvalidName("Your name should contain at least 3 characters");
      } else {
        setInvalidName("Your name is required");
      }
      isValid = false;
    } else {
      setIsInvalidName(false);
      setInvalidName("");
    }

    if (!email || !regex.test(email)) {
      setIsInvalidEmail(true);
      if (email) {
        setInvalidEmail("Your email is invalid");
      } else {
        setInvalidEmail("Your email is required");
      }
      isValid = false;
    } else {
      setIsInvalidEmail(false);
      setInvalidEmail("");
    }

    if (!message || message.length < 20) {
      setIsInvalidMessage(true);
      if (message) {
        setInvalidMessage("Your message should at least contain 20 characters");
      } else {
        setInvalidMessage("Your message is required");
      }
      isValid = false;
    } else {
      setIsInvalidMessage(false);
      setInvalidMessage("");
    }

    return isValid;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const contactUsSendButton: any = document.getElementById(
      "contact-us-send-button"
    );

    if (validate()) {
      setShowSpinner(true);
      const url =
        "https://us-central1-skill-surf.cloudfunctions.net/api/contactus";
      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          setInvalidEmail("");
          setInvalidName("");
          setInvalidMessage("");
          setShowSpinner(false);
          setShow(true);
          setEmail("");
          setName("");
          setMessage("");

          contactUsSendButton.disabled = true;

          let countdownNum = 60;
          incTimer();

          function incTimer() {
            setTimeout(function () {
              if (countdownNum !== 0) {
                countdownNum--;
                contactUsSendButton.textContent =
                  "Send Message: " + countdownNum;
                incTimer();
              } else {
                contactUsSendButton.textContent = "Send Message";
                contactUsSendButton.disabled = false;
              }
            }, 1000);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  return (
    <div className="bg-background fade-in">
      <NavBar background="bg-background" active="contact" />
      <section className="container contact-section d-flex align-items-center">
        <Row className="align-items-center">
          <Col className="col-12 col-sm-12 col-md-6 col-lg-6 text-center">
            <img
              src={Chat}
              className="img-fluid"
              alt="Vector representing Contact Us"
            />
          </Col>
          <Col className="col-12 col-sm-12 col-md-6 col-6 ">
            <h3
              className="font-family-class text-secondary"
              style={{ fontWeight: 500 }}
            >
              Contact Us
            </h3>
            <p className="font-family-class text-gray-2 fs-6">
              Reach us with all your requests, questions and feedback! If you
              have specific requirements about teaching content in related
              areas, reach out to us and let's explore how the content can be
              tailor-made for your institute.
            </p>
            <Form
              className="needs-validation"
              validated={false}
              onSubmit={handleSubmit}
            >
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Your Name"
                  name="name"
                  value={name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setName(e.target.value);
                  }}
                />
                {isInvalidName ? (
                  <p className="text-danger">{invalidName}</p>
                ) : (
                  <></>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  type="email"
                  placeholder="Your Email"
                  name="email"
                  value={email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(e.target.value);
                  }}
                />
                {isInvalidEmail ? (
                  <p className="text-danger">{invalidEmail}</p>
                ) : (
                  <></>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  as="textarea"
                  rows={8}
                  placeholder="Your Message"
                  name="message"
                  value={message}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setMessage(e.target.value);
                  }}
                />
                {isInvalidMessage ? (
                  <p className="text-danger">{invalidMessage}</p>
                ) : (
                  <></>
                )}
              </Form.Group>
              <Button
                className="send-btn font-family-class fs-6 text-white"
                variant="none"
                type="submit"
                style={{ fontWeight: 500 }}
                id="contact-us-send-button"
              >
                SEND
              </Button>
            </Form>
          </Col>
        </Row>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Body className="d-flex flex-column">
            <p className="font-family-class text-gray mb-0">
              Thank you for reaching out! We'll get back to you shortly.
            </p>
            <div className="d-flex justify-content-end mt-4">
              <Button
                variant="secondary"
                onClick={() => {
                  setShow(false);
                }}
              >
                Okay
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </section>
      <Spinner showSpinner={showSpinner} />
      <Footer />
    </div>
  );
};

export default ContactUs;
