import { IPolicy } from "../utils/utils";

export const refundPolicy: IPolicy = {
  policyTitle: "Refund Policy",
  description:
    "We value our students and understand that sometimes, things might not go as planned. This policy has been crafted keeping our students' best interests in mind.",
  subContent: [
    {
      policySubTitle: "a. General Guidelines",
      policyDescription: [
        {
          description:
            "Before enrolling in any course, we encourage all students to review the course description, requirements, and preview materials provided to ensure the course meets your expectations and learning needs.",
        },
        {
          description:
            "If you have any queries or need clarification about our courses before making a purchase, please reach out to our support team.",
        },
      ],
    },
    {
      policySubTitle: "b. Eligibility for Refund",
      policyDescription: [
        {
          description:
            "Students are eligible for a full refund within 7 days of purchasing the course, provided they have not accessed more than 20% of the course content. This is to ensure fair use and compensate for the knowledge and resources already utilized.",
        },
        {
          description:
            "After the 7 days period or if more than 20% of the course has been accessed (whichever comes first), no refunds will be granted.",
        },
      ],
    },
    {
      policySubTitle: "c. Exceptional Cases",
      policyDescription: [
        {
          description:
            "If a student experiences technical difficulties that prevent access to the course and it is caused by a failure on our end, a full refund will be granted regardless of the time elapsed since the purchase.",
        },
      ],
    },
    {
      policySubTitle: "d. Refund Process",
      policyDescription: [
        {
          description: `To initiate a refund, contact our support team at <a
            href="mailto: carelfinances@gmail.com"
          >
          carelfinances@gmail.com
          </a> with your purchase details, the reason for the refund request, and any supporting information.`,
        },
        {
          description:
            "Once your refund is approved, it will be processed within 21 business days. The amount will be credited back to your bank account.",
        },
      ],
    },
    {
      policySubTitle: "e. Course Changes and Updates",
      policyDescription: [
        {
          description:
            "We constantly update our courses to ensure they remain relevant and valuable to our students. If a course is updated after you have enrolled, but before you have completed it, you will have access to all the updates without any additional charge.",
        },
      ],
    },
    {
      policySubTitle: "f. Termination",
      policyDescription: [
        {
          description:
            "If a student is found violating our terms of service and code of conduct or engaging in any malpractice, their access to the course will be terminated immediately without any refund.",
        },
      ],
    },
    {
      policySubTitle: "g. Changes to the Refund Policy",
      policyDescription: [
        {
          description:
            "Skill Surf reserves the right to update or change this refund policy at any time. Any changes will be posted on this page, and students are encouraged to review the refund policy periodically.",
        },
      ],
    },
    {
      policySubTitle: "h. Contact Information",
      policyDescription: [
        {
          description: `For any queries regarding our refund policy or to initiate a refund request, please contact our support team via <a
            href="mailto: carelfinances@gmail.com"
          >
          carelfinances@gmail.com
          </a> We are here to assist you and ensure your learning experience with us is fruitful and hassle-free.`,
        },
      ],
    },
  ],
  active: "refund-policy",
};
