import microcontrollerBasedEmbeddedSystemDesignImage from "../../components/ProgramCatalog/assets/microcontroller-based-embedded-system-design.webp";
import entcImage from "../../components/CoursePage/assets/entc-logo.webp";
import unicImage from "../../components/CoursePage/assets/unic-logo.webp";
import dilankaWickramasinghe from "../../components/CoursePage/assets/instructor-img/Dilanka Wickramasinghe.webp";
import kalanaAbeywardena from "../../components/CoursePage/assets/instructor-img/Kalana Abeywardena.webp";
import samithAshan from "../../components/CoursePage/assets/instructor-img/Samith Ashan.webp";
import kithminWickremasinghe from "../../components/CoursePage/assets/instructor-img/Kithmin Wickremasinghe.webp";
import abarajithanGnaneswaran from "../../components/CoursePage/assets/instructor-img/Abarajithan Gnaneswaran.webp";
import thamiduNaveen from "../../components/CoursePage/assets/instructor-img/Thamidu_Naveen.webp";
import pahanMendis from "../../components/CoursePage/assets/instructor-img/Pahan Mendis.webp";
import chathuniWijegunawardana from "../../components/CoursePage/assets/instructor-img/Chathuni Wijegunawardana.webp";
import subodhaCharles from "../../components/CoursePage/assets/instructor-img/Subodha Charles.webp";
import rangaRodrigo from "../../components/CoursePage/assets/instructor-img/Ranga Rodrigo.webp";
import { ECourseStatus, ICourse } from "../utils/utils";
import microcontrolleer_24_course_flyer from "../../components/CoursePage/assets/microcontroller_2024.webp";

export const microcontrollerBasedEmbeddedSystemDesign: ICourse = {
  header: {
    courseImage: microcontrollerBasedEmbeddedSystemDesignImage,
    programCategory: "Short Course / On-Demand",
    difficultyLevel: "Beginner",
    courseTitle: "Microcontroller Based Embedded System Designs '24 (Batch 03)",
    specialization: "Internet of Things",
    courseDescription:
      "Explore the intricacies of Microcontroller-Based Embedded System Designs in Skill Surf’s short course, blending theory and hands-on experience for a tech-forward skill set.",
    registerButton: "Registration Closed!",
    registerLink: "",
    secondaryName: "Stay tuned for updates",
  },
  headerSecondSection: {
    starCount: 4.8,
    reviewCount: 50,
    registrationDeadline: "",
    parameterOne: "Estimated Time",
    parameterTwo: "8 Weeks",
    parameterThree: "",
    alternativeTag:"Third Intake ",
    collaborationLogos: [
      {
        collaborationLogo: entcImage,
        collaborationLogosName: "ENTC Logo",
      },
     
    ],
  },
  courseOutline: [
    {
      session: "",
      sessionContent: [
        {
          heading: "Introduction to microcontrollers",
          description:
            "Learn what microcontrollers are and what they are capable of.",
          subContent: [],
        },
        {
          heading: "Microcontroller programming",
          description:
            "Complete your first microcontroller programming project with Arduino (C++). Learn how to simulate functional components in microcontroller-based systems.",
          subContent: [],
        },
        {
          heading: "Schematic and PCB design",
          description:
            "Design schematics and printed circuit boards (PCB) for Microcontroller-based systems using electronic design automation (EDA) software. Learn the best practices in PCB design.",
          subContent: [],
        },
        {
          heading: "PCB manufacturing, assembly and soldering",
          description:
            "Manufacture and produce the final electronic circuit with the components populated on the PCB. Learn the best practices in manufacturing and soldering techniques.",
          subContent: [],
        },
        {
          heading: "Advanced microcontroller concepts",
          description:
            "Learn in-depth concepts in microcontroller-based system design while programming using embedded development boards such as STM32.",
          subContent: [],
        },
        {
          heading: "Cloud connectivity",
          description:
            "Learn how to send the sensor data through ESP32 to the cloud.",
          subContent: [],
        },
        {
          heading: "End-to-end IoT system design from sensor to cloud",
          description:
            "Combine the knowledge you have gathered to develop an end-to-end IoT system.",
          subContent: [],
        },
      ],
    },
  ],
  faqs: [
    {
      question:
        "I have no prior knowledge of electronics or programming. Will I be able to do this course?",
      answer: "Yes. We teach all the required skills from the very basics.",
    },
    {
      question:
        "Do I need electronic equipment/hardware to complete this course?",
      answer:
        "No. The course is designed in such a way that you can complete the course and earn the completion certificate without any hardware requirement. However, if you are interested in implementing everything on hardware, we will help you to purchase the required equipment/hardware.",
    },
    {
      question:
        "Will I be able to build things at the end of the course, or is it only theory?",
      answer:
        "Of course, you will be able to build things. The course is outcome-oriented, so at the end of the course, you will know how to develop a cool product from scratch!",
    },
    {
      question: "Is the course taught online?",
      answer:
        "Yes. The course lectures are all conducted online.",
    },
    {
      question: "Will lecture recordings be made available?",
      answer:
        "Yes. All course materials will be made available through an online learning management system called Moodle. All paid participants will be granted access to the course Moodle page.",
    },
  ],
  experts: [
    {
      image: kithminWickremasinghe,
      name: "Mr. Kithmin Wickremasinghe",
      designation: "MASc Student in Electrical and Computer Eng., UBC, Canada",
    },
    {
      image: abarajithanGnaneswaran,
      name: "Mr. Abarajithan Gnaneswaran",
      designation: "PhD Candidate, University of California San Diego, USA",
    },
    {
      image: thamiduNaveen,
      name: "Mr. Thamidu Naveen",
      designation: "Lecturer, EE, UoM",
    },
    
   
    {
      image: dilankaWickramasinghe,
      name: "Mr. Dilanka Wickramasinghe",
      designation:
        "COO, CircuitBreakers Robotics (Pvt) Ltd | IXD Labs (Pvt) Ltd",
    },
    {
      image: pahanMendis,
      name: "Mr. Pahan Mendis",
      designation: "Electronics Engineer, Paraqum Technologies (Pvt) Ltd",
    },
    {
      image: chathuniWijegunawardana,
      name: "Ms. Chathuni Wijegunawardana",
      designation: "4th Year Undergraduate, ENTC, UoM",
    },
    {
      image: subodhaCharles,
      name: "Dr. Subodha Charles",
      designation: "Senior Lecturer, ENTC, UoM",
    },
    {
      image: rangaRodrigo,
      name: "Dr. Ranga Rodrigo",
      designation: "HoD/Senior Lecturer, ENTC, UoM",
    },
  ],
  studentReviews: [
    "This course was very helpful for me to gain a fundamental knowledge about the Embedded systems. The way of arranging the course schedule and delivering was very proper and urged me to gain more knowledge. Thank you to all the people who are behind the arranging of such a great course.",
    "One of the best investments I've ever made. Great collection of instructors. They were friendly and responsive. Great syllabus. Hope you will cover STM32 and Raspberry Pi in upcoming courses.",
    "This course has very valuable content about the IoT. And I wish you all to Dr.Subodha and other all lectures. Thank you for all.",
    "Really good flow, beginner to complex way of delivering content, very organized presentations & confident lecture panel, and it is a great value addition to the CV for us. Thank you for conducting such a practical course.",
    "It is really good. Well worth the money invested in this course. 10/10 would recommend it to friends and I will join for future iterations of this course.",
  ],
  facts: [
   
  ],
  showSchedule:true,
  classSchedule: [
    "Each Saturday: 6 AM -  Releasing videos for the week",
    "Each Sunday: 9 AM - 11 AM - Lecture",
   
  ],
  prerequisite:
    "Anyone willing to get started with microcontroller-based embedded system designs can join. No prior experience is required.",
  isCourseFlyer: true,
  flyer: {
    description:
      'Learn more about Microcontroller-Based Embedded System Designs \'24 short course from the course announcement flyer',
    button: "View Course Flyer",
    image: microcontrolleer_24_course_flyer,
  },
  courseStatus: ECourseStatus.NEW,
  showTimer: false,
  learnMore: {
    description:
      "Learn more about Revolutionizing Healthcare: Unveiling the Wonders of Medibox",
    button: "Read More",
    articleLink:
      "https://medium.com/@SkillSurfEdu/revolutionizing-healthcare-unveiling-the-wonders-of-medibox-b51c7866f897",
  },

    /* firstSessionFreeDetails: {
    instruction: {
      isInstructive: false,
      instructiveText:
        "The first session, which was free of charge to everyone, was held on 04th February 2024. The recording of the session is made available below.",
      instructiveTextSecond:
        "Only paid participants can attend from day 2 onwards. So, be sure to complete the payment by",
      date: "",
      dateSecond: "23 February 2024, 03.00 PM IST",
      buttonText: "View Course Outline",
      buttonTextInfo: "View Slides",
      viewIntruction: "View Instructions for Next Steps",
      instructionVideoTopic:"Microcontroller Based Embedded System Designs '24 First Session Video",
      instructionVideoUrl: "qeHfQ-qu9Mg",
      videoTwoTopic:"Microcontroller Based Embedded System Designs '24 Enrollment Instructions",
      videoTwoUrl:"pdLNamvKvlw",
      viewIntructionLink:
        "https://www.youtube.com/watch?v=pdLNamvKvlw",
      buttonLink:
        "https://drive.google.com/file/d/15ZD08Kq4sB0Nqs7M6FLH34aSrSqKKh5A/view",
      buttonLinkInfo:
        "https://uniofmora-my.sharepoint.com/:p:/g/personal/scharles_uom_lk/EYaYK8OA6clMtLeDwhKlLVMBYiI1ImHaiZzLdT1V8nXAMQ?e=l8uy1i",
      moreInformation:
        "For any questions, please send a WhatsApp message to Mr. Damith Kandage at +94 70 405 9651 or Ms. Shehani Jayasinghe at +94 767 666 555",
    },
  },  */
};
