import embeddedMachineLearningForEdgeComputingImage from "../../components/ProgramCatalog/assets/embedded-machine-learning-for-edge-computing.webp";
import entcImage from "../../components/CoursePage/assets/entc-logo.webp";
import unicImage from "../../components/CoursePage/assets/unic-logo.webp";
//import amayaDharmasiri from "../../components/CoursePage/assets/instructor-img/Amaya_Dharmasiri.webp";
//import ireshJayawardana from "../../components/CoursePage/assets/instructor-img/Iresh Jayawardana.webp";
//import kanchanaRanasinghe from "../../components/CoursePage/assets/instructor-img/Kanchana_Ranasinghe.webp";
//import sadeepJayasumana from "../../components/CoursePage/assets/instructor-img/Sadeep Jayasumana.webp";
import sahanHemachandra from "../../components/CoursePage/assets/instructor-img/Sahan Hemachandra.webp";
//import senuraMaduwantha from "../../components/CoursePage/assets/instructor-img/Senura Maduwantha.webp";
//import teharaFonseka from "../../components/CoursePage/assets/instructor-img/Tehara Fonseka.webp";
//import visalRajapakse from "../../components/CoursePage/assets/instructor-img/Visal Rajapakse.webp";
import kithminWickremasinghe from "../../components/CoursePage/assets/instructor-img/Kithmin Wickremasinghe.webp";
import abarajithanGnaneswaran from "../../components/CoursePage/assets/instructor-img/Abarajithan Gnaneswaran.webp";
import subodhaCharles from "../../components/CoursePage/assets/instructor-img/Subodha Charles.webp";
import rangaRodrigo from "../../components/CoursePage/assets/instructor-img/Ranga Rodrigo.webp";

import pahanmendis from "../../components/CoursePage/assets/instructor-img/Pahan Mendis.webp";
import chathuniw from "../../components/CoursePage/assets/instructor-img/Chathuni Wijegunawardana.webp";
import pramudithas from "../../components/CoursePage/assets/instructor-img/Pamuditha.webp";
import mohamedA from "../../components/CoursePage/assets/instructor-img/Afham.webp";
import sanjana from "../../components/CoursePage/assets/instructor-img/Sanjana.webp";
import devnith from "../../components/CoursePage/assets/instructor-img/Devnith.webp";
import damith from "../../components/CoursePage/assets/instructor-img/Damith.webp";

import { ECourseStatus, ICourse } from "../utils/utils";
import Embedded_Machine_Learning_for_Edge_Computing_flyer from "../../components/CoursePage/assets/Embedded_AI_Main_Flyer_2024.webp";

export const embeddedMachineLearningForEdgeComputing: ICourse = {
  header: {
    courseImage: embeddedMachineLearningForEdgeComputingImage,
    programCategory: "Short Course",
    difficultyLevel: "Intermediate",
    courseTitle: "Embedded Machine Learning for Edge Computing 2024 - Batch 02",
    specialization: "Internet of Things",
    courseDescription:
      "Embedded Machine Learning for Edge Computing is deploying Machine Learning (ML) applications in devices throughout the physical world. Get comprehensive training to develop and deploy real-world ML applications on embedded systems.",
    registerButton: "Registration Closed!",
    registerLink: "",
    secondaryName: "Stay tuned for updates",
  },
  headerSecondSection: {
    starCount: 4.4,
    reviewCount: 50,
    registrationDeadline: "",
    parameterOne: "Estimated Time",
    parameterTwo: "8 Weeks",
    parameterThree: "",
    collaborationLogos: [
      {
        collaborationLogo: entcImage,
        collaborationLogosName: "ENTC Logo",
      },
      {
        collaborationLogo: unicImage,
        collaborationLogosName: "UNIC Logo",
      },
    ],
  },
  courseOutline: [
    {
      session: "",
      sessionContent: [
        {
          heading: "Introduction to machine learning",
          description:
            "Get an intuitive understanding of machine learning with hands-on examples using Google Colab, Pytorch, TensorFlow and TensorFlow playground",
          subContent: [],
        },
        {
          heading: "Building and training deep learning models",
          description:
            "Learn how a deep learning training pipeline in the cloud will work with real-world examples and code them on your own with colab notebooks.",
          subContent: [],
        },
        {
          heading: "Programming in embedded systems",
          description:
            "Make the shift from Python to C++. Learn about embedded hardware and software that can facilitate deploying machine learning at the edge usingTinyML on Arduino, and Cube.AI on STM32.",
          subContent: [],
        },
        {
          heading: "Machine learning pipeline at the edge",
          description:
            "Learn how to develop your own end-to-end machine learning pipeline at the edge with the Arduino Nano 33 BLE Sense and its multitude of sensing capabilities.",
          subContent: [],
        },
        {
          heading: "Training, validating and deploying",
          description:
            "Step-by-step process to train, validate, deploy and test two real-world embedded machine learning applications using the Arduino Nano 33 BLE Sense.",
          subContent: [],
        },
      ],
    },
  ],
  faqs: [
    {
      question:
        "I have no prior knowledge of machine learning or embedded systems. Will I be able to do this course?",
      answer:
        "We expect you to have basic knowledge of programming in any language. However, no prior machine learning or embedded systems knowledge is required. We teach all the required skills from the very basics.",
    },
    {
      question:
        "Do I need electronic equipment/hardware to complete this course?",
      answer:
        "No. The course is designed in such a way that you can complete the course and earn the completion certificate without any hardware requirement. However, if you are interested in implementing everything on hardware, we will help you to purchase the required equipment/hardware.",
    },
    {
      question:
        "Will I be able to build things at the end of the course, or is it only theory?",
      answer:
        "Of course, you will be able to build things. The course is outcome-oriented, so at the end of the course, you will know how to develop a cool product from scratch!",
    },
    {
      question: "Is the course taught online?",
      answer:
        "Yes. The course lectures are all conducted online. We will arrange hands-on sessions at ENTC laboratories as required.",
    },
    {
      question: "Will lecture recordings be made available?",
      answer:
        "Yes. All course materials will be made available through an online learning management system called Moodle. All paid participants will be granted access to the course Moodle page.",
    },
  ],
  experts: [
    {
      image: pahanmendis,
      name: "Mr. Pahan Mendis",
      designation: "Electronics Engineer, Paraqum Technologies (Pvt) Ltd.",
    },
    {
      image: chathuniw,
      name: "Ms. Chathuni Wijegunawardana",
      designation: "Electronics Engineer, Paraqum Technologies (Pvt) Ltd.",
    },
    {
      image: pramudithas,
      name: "Mr. Pamuditha Somarathne",
      designation: "PhD Candidate, The University of Sydney, Australia",
    },
    {
      image: mohamedA,
      name: "Mr. Mohamed Afham",
      designation: "PhD student in Computer Vision, TU Darmstadt, Germany",
    },
    {
      image: sanjana,
      name: "Ms. Sanjana Kapukotuwa",
      designation: "4th Year Undergraduate, ENTC, UoM",
    },
    {
      image: devnith,
      name: "Mr. Devnith Wijesinghe",
      designation: "3rd Year Undergraduate, ENTC, UoM",
    },
    {
      image: kithminWickremasinghe,
      name: "Mr. Kithmin Wickremasinghe",
      designation: "MASc Student in Electrical and Computer Eng. , UBC, Canada",
    },
    {
      image: abarajithanGnaneswaran,
      name: "Mr. Abarajithan Gnaneswaran",
      designation:
        "PhD Student in Electrical and Computer Eng. , UC San Diego, USA",
    },
    {
      image: damith,
      name: "Mr. Damith Kawshan",
      designation:
        "Research Project Officer, NTU, Singapore",
    },
    {
      image: sahanHemachandra,
      name: "Mr. Sahan Hemachandra",
      designation: "Senior Software Engineer, WSO2",
    },
    {
      image: subodhaCharles,
      name: "Dr. Subodha Charles",
      designation: "Senior Lecturer, ENTC, UoM",
    },
    {
      image: rangaRodrigo,
      name: "Dr. Ranga Rodrigo",
      designation: "Former HoD/Senior Lecturer, ENTC, UoM",
    },
  ],
  studentReviews: [
    "On the 1st day, when I saw the registration details, I went through the course curriculum and I joined the course with an expectation. The team fulfilled my expectations 100%.",
    "The course is very interesting and provides a good knowledge in the intended learning area. I would like to see more courses like this in future.",
    "This was a great course. Although the instructors couldn't touch all the aspects, guiding the students on what to follow and refer to is a great thing. Grateful for the organizers and instructors sharing their knowledge. Hope to join the next courses as well.",
    "The personal ML/embedded projects that the instructors shared as case studies were really inspiring. Sharing the ups and downs and what to expect along this path is a real motivator!",
    "Flow of the content was really great. It will really help to beginners who are entering to ML & Edge computing.",
  ],
  facts: [
    {
      fact: "A review by Gartner found that as of 2018, just 10% of all data was processed at the edge. However, Gartner expects that by 2025",
      factBold: "75% of all porcessing with happen at the edge",
      author: "-digi.com-",
    },
    {
      fact: "The global market for Embedded AI is expected to grow at a 5.4% CAGR from 2021 to 2026, reaching about ",
      factBold: "USD 38.87 billion",
      author: "-dac.digital-",
    },
  ],
  classSchedule: [],
  prerequisite:
    "Basic knowledge of programming in any language. Microcontroller programming knowledge will be a plus but not mandatory.",
  
  


  isCourseFlyer: true,
  flyer: {
    description:
      'Learn more about Embedded Machine Learning for Edge Computing 2024 short course from the course announcement flyer',
    button: "View Course Flyer",
    image: Embedded_Machine_Learning_for_Edge_Computing_flyer,
  },
  courseStatus: ECourseStatus.NEW,
  showTimer: false,
  learnMore: {
    description:
      "Read More About Embedded ML",
    button: "Read More",
    articleLink:
      "https://medium.com/@SkillSurfLK/embedded-machine-learning-for-edge-computing-4598eb8df1b1",
  },
  
     /*   firstSessionFreeDetails: {
    instruction: {
      isInstructive: false,
      instructiveText:
        "The first session, which was free of charge to everyone, was held on 02nd June 2024. The recording of the session is made available below.",
      instructiveTextSecond:
        "Only paid participants can attend from day 2 onwards. So, be sure to complete the payment by",
      date: "",
      dateSecond: "21 June 2024, 03.00 PM IST",
      buttonText: "View Course Outline",
      buttonTextInfo: "View Slides",
      viewIntruction: "View Instructions for Next Steps",
      instructionVideoTopic:"Embedded Machine Learning for Edge Computing 2024 First Session Video",
      instructionVideoUrl: "",
      videoTwoTopic:"Enrollment Instructions",
      videoTwoUrl:"pdLNamvKvlw",
      viewIntructionLink:
        "https://drive.google.com/file/d/1dWRYQSgkOO09zSkdqYc-DAAjNExhjZbZ/view?usp=sharing",
      buttonLink:
        "",
      buttonLinkInfo:
        "https://uniofmora-my.sharepoint.com/:b:/g/personal/scharles_uom_lk/EbQRITK2_gVAudAh1UVJWPsBsjrLOuN5FIT6qSi208HJlQ?e=U0dATq",
      moreInformation:
        "For any questions, please send a WhatsApp message to Mr. Damith Kandage at +94 70 405 9651 or Ms. Shehani Jayasinghe at +94 767 666 555",
    },
  },  */ 
};
