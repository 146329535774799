export const privacyPolicy = {
  policyTitle: "Privacy Policy",
  description:
    "At Skill Surf, we are committed to safeguarding the privacy of our users. This Privacy Policy outlines the types of information we collect, how we use it, and the measures we take to protect your personal data.",
  subContent: [
    {
      policySubTitle: "a. Information We Collect",
      policyDescription: [
        {
          description:
            "Personal Information: When you register for a course, we may ask for personal information such as your name, email address and contact number.",
        },
        {
          description:
            "Course Data: We collect data related to your progress, quiz scores, and course activities.",
        },
        {
          description:
            "Usage Data: This includes information about how you access and use our website, like your IP address, browser type, and the pages you visit.",
        },
      ],
    },
    {
      policySubTitle: "b. How We Use Your Information",
      policyDescription: [
        {
          description: "To provide, maintain, and improve our services.",
        },
        {
          description:
            "To process transactions and send related information, including confirmations and receipts.",
        },
        {
          description: "For customer support and to respond to your queries.",
        },
        {
          description:
            "For internal analytics and to understand how users interact with our platform.",
        },
      ],
    },
    {
      policySubTitle: "c. Cookies",
      policyDescription: [
        {
          description:
            "We use cookies to enhance your user experience, understand site usage, and improve our services. You can control cookies through your browser settings.",
        },
      ],
    },
    {
      policySubTitle: "d. Sharing Your Information",
      policyDescription: [
        {
          description:
            "We do not sell or rent your personal information. We might share your data with the following:",
          subDescription: [
            {
              description:
                "Service providers who assist in the operation of our platform.",
            },
            {
              description:
                "When required by law or to respond to legal processes.",
            },
          ],
        },
        {
          description:
            "To protect our users, enforce our terms of service, or in emergency situations to safeguard personal or public safety.",
        },
      ],
    },
    {
      policySubTitle: "e. Data Security",
      policyDescription: [
        {
          description:
            "We employ a range of measures to protect your data, including encryption, secure servers, and regular assessments. However, no method is 100% secure, and we cannot guarantee its absolute security.",
        },
      ],
    },
    {
      policySubTitle: "f. Your Rights",
      policyDescription: [
        {
          description: "Depending on where you reside, you may have rights to:",
          subDescription: [
            {
              description: "Access the personal data we hold about you.",
            },
            {
              description: "Correct inaccuracies in your data.",
            },
            {
              description: "Withdraw your consent.",
            },
            {
              description: "Object to certain uses of your data.",
            },
            {
              description: "Delete your personal data.",
            },
            {
              description: "Lodge a complaint.",
            },
          ],
        },
        {
          description: "Please contact us for any of the above requests.",
        },
      ],
    },
    {
      policySubTitle: "g. Children’s Privacy",
      policyDescription: [
        {
          description:
            "Our platform is not intended for individuals under the age of 13. We do not knowingly collect or solicit personal data from children. If you believe that we might have any such data, please contact us.",
        },
      ],
    },
    {
      policySubTitle: "h. Changes to This Policy",
      policyDescription: [
        {
          description:
            "We may occasionally update this Privacy Policy. We encourage you to review it regularly. Your continued use of our services signifies your acceptance of any changes.",
        },
      ],
    },
    {
      policySubTitle: "i. Contact Us",
      policyDescription: [
        {
          description: `For questions about this Privacy Policy or to exercise your rights, please contact our support team via <a
            href="mailto: carelfinances@gmail.com"
          >
          carelfinances@gmail.com.
          </a>`,
        },
      ],
    },
  ],
  active: "privacy-policy",
};
