import React from "react";
import "./ProgramCatalog.scss";
import CourseCard from "../../shared/components/Course-Card/CourseCard";
import NavBar from "../../shared/components/NavBar/NavBar";
import Footer from "../../shared/components/Footer/Footer";
import { useState } from "react";
import { cardData } from "../../shared/programCatalogData";
import ReactPaginate from "react-paginate";

const ProgramCatalog: React.FC = () => {
  const [cardInfo] = useState(cardData);
  const [filteredcardInfo, setFilteredcardInfo] = useState(cardData);

  var itemsPerPage = 3; // Number of CourseCards per page
  const [currentPage, setCurrentPage] = useState(0);
  var startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const handleFilter = (e: any) => {
    if (e.target.value === "all") {
      setFilteredcardInfo(cardInfo);
    } else if (e.target.value === "workshop") {
      setCurrentPage(0);
      setFilteredcardInfo(cardInfo.filter((item) => item.type === "Workshop"));
    } else if (e.target.value === "short course") {
      setFilteredcardInfo(
        cardInfo.filter((item) => item.type === "Short Course")
      );
    }
  };

  const handlePageChange = (selected: { selected: number }) => {
    setCurrentPage(selected.selected);
    window.scrollTo(0, 0);
  };

  return (
    <div className="fade-in">
      <NavBar active="catalog" />
      <div className="section-dark container-fluid mb-5">
        <div className="d-flex justify-content-around">
          <div className="row row-cols-lg-auto g-3 align-items-center justify-content-center ">
            <div className="col-md-12 text-center">
              <h3 className=" fs-3 text-white text-center">Program Catalog</h3>
              <p className="fs-7 text-white text-center">
                Find a program that matches your interest
              </p>
              <div className="mt-4">
                <p className="fs-7 text-white text-center m-2">Program Type:</p>

                <select
                  id="level"
                  name="level"
                  className="dropdown dropdown-width fs-6"
                  onChange={handleFilter}
                >
                  <option value="all">All</option>
                  <option value="short course">Short Course</option>
                  <option value="workshop">Workshop</option>
                </select>
              </div>
            </div>

            <div
              className="col-md-6  container-fluid"
              style={{ display: "none" }}
            >
              <input
                type="text"
                className="search-textbox form-control fs-6"
                placeholder="Search Program"
              />
              <div className="row row-cols-lg-auto g-3 ">
                <div className="col-md-4">
                  <select
                    id="FocusArea"
                    name="FocusArea"
                    className="dropdown fs-6"
                  >
                    <option disabled selected>
                      Focus Area
                    </option>
                    <option value="action">action1</option>
                    <option value="action">action2</option>
                    <option value="action">action3</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <select id="level" name="level" className="dropdown  fs-6">
                    <option disabled selected>
                      Difficulty Level
                    </option>
                    <option value="action">action1</option>
                    <option value="action">action2</option>
                    <option value="action">action3</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <select
                    id="Duration"
                    name="Duration"
                    className="dropdown fs-6"
                  >
                    <option disabled selected>
                      Duration
                    </option>
                    <option value="action">action1</option>
                    <option value="action">action2</option>
                    <option value="action">action3</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-5">
        <div className="row">
          {filteredcardInfo
            .slice(startIndex, endIndex) // Slice the CourseCards array based on the current page
            .map((courseItem) => (
              <div className="col-md-12" key={courseItem.title}>
                <CourseCard
                  cardImage={courseItem.cardImage}
                  title={courseItem.title}
                  type={courseItem.type}
                  difficulty={courseItem.difficulty}
                  description={courseItem.description}
                  ratingCount={courseItem.ratingCount}
                  starCount={courseItem.starCount}
                  learning={courseItem.learning}
                  conductedBy={courseItem.conductedBy}
                  isLineBreak={courseItem.isLineBreak}
                  prereq={courseItem.prereq}
                  actionDownload={courseItem.actionDownload}
                  actionProgramDetails={courseItem.actionProgramDetails}
                  IsSpecialization={courseItem.IsSpecialization}
                  specialization={courseItem.specialization}
                  isNewCourse={courseItem.isNewCourse}
                  duration={courseItem.duration}
                  isOneDayWorkShop={courseItem.isOneDayWorkShop}
                  isOverlay={courseItem.isOverlay}
                  buttonText={courseItem.buttonText}
                  currentState={courseItem.currentState}
                  overlayText={courseItem.overlayText}
                  courseFee={courseItem.courseFee}
                  discount={courseItem.discount}
                  cardImageAlt={courseItem.cardImageAlt}
                  isNewIntake={courseItem.isNewIntake}
                  newIntakeText={courseItem.newIntakeText}
                />
              </div>
            ))}
        </div>

        <div className="pagination-container">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(filteredcardInfo.length / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination justify-content-center"}
            activeClassName={"active"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            nextClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextLinkClassName={"page-link"}
          />
        </div>
      </div>
      <Footer isFinancialInquiries={true} />
    </div>
  );
};

export default ProgramCatalog;
