import entcImage from "../../components/CoursePage/assets/entc-logo.webp";
import kithminWickremasinghe from "../../components/CoursePage/assets/instructor-img/Kithmin Wickremasinghe.webp";
import subodhaCharles from "../../components/CoursePage/assets/instructor-img/Subodha Charles.webp";
import rangaRodrigo from "../../components/CoursePage/assets/instructor-img/Ranga Rodrigo.webp";
import chamitha from "../../components/CoursePage/assets/instructor-img/Chamitha.webp";
import randiWakkumbura from "../../components/CoursePage/assets/instructor-img/Randi Wakkumbura-.webp";
import sandushan from "../../components/CoursePage/assets/instructor-img/Sandushan.webp";
import yashen from "../../components/CoursePage/assets/instructor-img/Yashen.webp";
import flyer from "../../components/CoursePage/assets/CFSDW main flyer.webp";
import { ECourseStatus, ICourse } from "../utils/utils";
import cryptographyPaymentDetailsPDF from "../../pdfs/Payment Details - Cryptography Fundamentals_ Securing the Digital World.pdf";
import cryptographyCourseOutline from "../../pdfs/CFSDW-CourseOutline.pdf";

export const cryptographyFundamentalsSecuringTheDigitalWorld: ICourse = {
  header: {
    programCategory: "Short Course",
    difficultyLevel: "Beginner",
    courseTitle: "Cryptography Fundamentals: Securing the Digital World",
    specialization: "Cybersecurity",
    courseDescription:
      "Build a rock-solid foundation: master cryptography fundamentals with hands-on experience to safeguard the digital world",
    registerButton: "Registration Closed!",
    registerLink: "",
    secondaryName: "Stay tuned for updates",
  },
  headerSecondSection: {
    starCount: 0,
    reviewCount: 0,
    registrationDeadline: "",
    parameterOne: "Estimated Time",
    parameterTwo: "8 Weeks",
    parameterThree: "New Course",
    collaborationLogos: [
      {
        collaborationLogo: entcImage,
        collaborationLogosName: "ENTC Logo",
      },
    ],
  },
  courseOutline: [
    {
      session: "",
      sessionContent: [
        {
          heading: "Introduction to cryptography",
          description: "",
          subContent: [
            {
              heading: "",
              description:
                "Importance of cryptography in securing digital information",
            },
            {
              heading: "",
              description: "Historical overview of cryptography",
            },
            {
              heading: "",
              description: "Understanding security through the CIA triad",
            },
          ],
        },
        {
          heading: "Symmetric key cryptography",
          description: "",
          subContent: [
            {
              heading: "",
              description:
                "Principles and techniques of symmetric key encryption",
            },
            {
              heading: "",
              description: "Modes of operation and key management",
            },
          ],
        },
        {
          heading: "Key exchange",
          description: "",
          subContent: [],
        },
        {
          heading: "Asymmetric key cryptography",
          description: "",
          subContent: [
            {
              heading: "",
              description: "Fundamentals of asymmetric key encryption",
            },
            {
              heading: "",
              description: "Public-key infrastructure (PKI)",
            },
            {
              heading: "",
              description: "Digital signatures",
            },
          ],
        },
        {
          heading: "Hash functions",
          description: "",
          subContent: [
            {
              heading: "",
              description: "Principals of hash functions",
            },
            {
              heading: "",
              description: "Applications of hash functions",
            },
          ],
        },
        {
          heading: "Passwords",
          description: "",
          subContent: [
            {
              heading: "",
              description: "Attacks against passwords and countermeasures",
            },
          ],
        },
        {
          heading: "Going beyond the fundamentals..what’s next?",
          description: "",
          subContent: [],
        },
      ],
    },
  ],
  faqs: [
    {
      question:
        "I have no prior knowledge of Cryptography. Will I be able to do this course?",
      answer:
        "Yes. We teach all the required skills. No prior experience is required. Even school students can take part.",
    },
    {
      question:
        "Do I need electronic software licenses to complete this course?",
      answer:
        "You will need software tools, but all the tools we are using will be free. We will teach you how to get them working.",
    },
    {
      question:
        "Will I be able to build things at the end of the course, or is it only theory?",
      answer:
        "Of course, you will be able to build things. The course is outcome-oriented, so at the end of the course, you will know how to understand and develop real-world security countermeasures.",
    },
    {
      question: "Is the course taught online?",
      answer: "Yes. The course lectures are all conducted online.",
    },
    {
      question: "Will lecture recordings be made available?",
      answer:
        "Yes. All course materials will be made available through an online learning management system. All paid participants will be granted access to that.",
    },
  ],
  experts: [
    {
      image: subodhaCharles,
      name: "Dr. Subodha Charles",
      designation: "Senior Lecturer, ENTC, UoM",
    },
    {
      image: yashen,
      name: "Mr. Yashen Waduge",
      designation: "Senior Engineer Information Security, SentryLabs",
    },
    {
      image: randiWakkumbura,
      name: "Ms. Randi Wakkumbura",
      designation: "Cyber Security Engineer, Vetstoria",
    },
    {
      image: sandushan,
      name: "Mr. Sandushan Ranaweera",
      designation: "Visiting Instructor, ENTC, UoM",
    },
    {
      image: kithminWickremasinghe,
      name: "Mr. Kithmin Wickremasinghe",
      designation: "MASc Student in Electrical and Computer Eng., UBC, Canada",
    },
    {
      image: chamitha,
      name: "Dr. Chamitha De Alwis",
      designation: "Lecturer in Cybersecurity, University of Bedfordshire",
    },
    {
      image: rangaRodrigo,
      name: "Dr. Ranga Rodrigo",
      designation: "HoD/Senior Lecturer, ENTC, UoM",
    },
  ],
  studentReviews: [
    "This was very good. I had almost 0 experience in this. As a beginner I learnt a lot. Thank you very much. The speed was alright, almost nothing to complain about. Thank you once again.",
    "Excellent session. Nice visuals. Clear explanations. Speed was adequate so that students can grasp the idea. looking forward for advanced stuff. Thank you.",
    "Very much informative, clear and interactive. Appreciate your sharp and broad knowledge on the subject!",
    "Thank you for conducting this session. It was really helpful in understanding the basics of digital designs.",
    "The session was really really good. This makes to get into academic projects very easy. The pace of the session was also good. I would like to learn more advanced concepts based on this foundation",
  ],
  facts: [
    {
      fact: "Cybersecurity skills are in high demand. With the increasing frequency and complexity of cyber threats, there is a significant shortage of skilled cybersecurity professionals. According to industry reports, millions of cybersecurity job positions are currently unfilled worldwide, presenting ample career opportunities for those with the necessary skills and knowledge.",
      factBold: "",
      author: "Cybersecurity Ventures",
    },
    {
      fact: "According to a report by Cybersecurity Ventures, the global cost of cybercrime is projected to reach $10.5 trillion annually by 2025, emphasizing the significant financial impact of cyberattacks.",
      factBold: "",
      author: "Cybersecurity Ventures",
    },
    {
      fact: "The IBM Security X-Force Threat Intelligence Index 2021 reveals that around 95% of security incidents involve human error or behavior, highlighting the critical role of cybersecurity awareness and training.",
      factBold: "",
      author: "IBM Security",
    },
  ],
  classSchedule: [
    "23 September 2023 (Saturday): 1 PM - 5 PM (Anyone can join for free)",
    "30 September 2023 (Saturday): 2 PM - 5 PM",
    "15 October 2023 (Sunday): 10 AM - 2 PM",
    "21 October 2023 (Saturday): 1 PM - 5 PM",
    "22 October 2023 (Sunday): 10 AM - 2 PM",
    "28 October 2023 (Saturday): 1 PM - 5 PM - No Class (Vap Full Moon Poya Day)",
    "29 October 2023 (Sunday): 10 AM - 2 PM",
    "4 November 2023 (Saturday): 1 PM - 5 PM",
    "5 November 2023 (Sunday): 10 AM - 2 PM",
    "11 November 2023 (Saturday): 1 PM - 5 PM",
    "12 November 2023 (Sunday): 10 AM - 2 PM - No Class (Deepavali Festival Day)",
    "18 November 2023 (Saturday): 1 PM - 5 PM",
    "19 November 2023 (Sunday): 10 AM - 2 PM",
    "25 November 2023 (Saturday): 1 PM - 5 PM",
    "26 November 2023 (Sunday): 10 AM - 2 PM - No Class (III Full Moon Poya Day)",
    "2 December 2023 (Saturday): 1 PM - 5 PM",
    "3 December 2023 (Sunday): 10 AM - 2 PM",
    "10 December 2023 (Sunday): 10 AM - 2 PM",
  ],
  prerequisite:
    "No prior experience in cryptography is required. Anyone with basic programming knowledge can take part.",
  isCourseFlyer: true,
  flyer: {
    description:
      "Learn more about Cryptography Fundamentals: Securing the Digital World short course from the course announcement flyer",
    button: "View Course Flyer",
    image: flyer,
  },
  courseStatus: ECourseStatus.NEW,
  showTimer: false,
  learnMore: {
    description:
      "Learn more about Cryptography Fundamentals: Securing the Digital World",
    button: "Read More",
    articleLink: "https://bit.ly/entc-Crypto-Fundamentals-Article",
  },
  youtubeVideoUrl: "https://www.youtube-nocookie.com/embed/pMGCZcHBgXA",
  timerDate: "2023-09-18 00:00",
  isCourseFee: false,
  courseFeeAmount: "Rs. 22,500",
  discountAmount: "60% Discount for Students",
  // firstSessionFreeDetails: {
  //   instruction: {
  //     isInstructive: true,
  //     instructiveText:
  //       "The first session, which was free of charge to everyone, was held on 23 September 2023. The recording of the session is made available below.",
  //     instructiveTextSecond:
  //       "Only paid participants can attend from day 2 onwards. So, be sure to complete the payment by",
  //     date: "",
  //     dateSecond: "13 October 2023 (Friday) at 3 PM IST.",
  //     buttonText: "Course Outline",
  //     buttonTextInfo: "Slides of the Info Session",
  //     viewIntruction: "View Instructions for Next Steps",
  //     instructionVideoUrl: "PwmvKylmACw?si=sXVAHCIMjMYU-VGc",
  //     viewIntructionLink: cryptographyPaymentDetailsPDF,
  //     buttonLink: cryptographyCourseOutline,
  //     buttonLinkInfo:
  //       "https://uniofmora-my.sharepoint.com/personal/scharles_uom_lk/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fscharles%5Fuom%5Flk%2FDocuments%2FENTC%2FShortCourses%2FCFSDW%2FContent%2F0%2E%20Info%20Session%2FSlides%2FCFSDW%2DD1%2DIntroduction%2Epdf&parent=%2Fpersonal%2Fscharles%5Fuom%5Flk%2FDocuments%2FENTC%2FShortCourses%2FCFSDW%2FContent%2F0%2E%20Info%20Session%2FSlides&ga=1",
  //     moreInformation:
  //       "For any questions, please send a WhatsApp message to Mr. Damith Kandage at +94 70 405 9651",
  //   },
  // },
};
