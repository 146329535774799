import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import footerLogo from "./assets/footer-logo.png";
import mailIcon from "./assets/mail-icon.png";
import whatsapp_icon from "./assets/wa-fill.svg";
import fbIcon from "./assets/fb.svg";
import linkedinIcon from "./assets/in.svg";
import igIcon from "./assets/ig.svg";
import twitterIcon from "./assets/twitter.svg";
import youtubeIcon from "./assets/youtube.svg";
import github from "./assets/github.svg";
import "./Footer.scss";
import { NavLink } from "react-router-dom";
interface footerProps {
  isFinancialInquiries?: boolean;
  active?: string;
}
const Footer: React.FC<footerProps> = (props) => {
  return (
    <div className="footer-container">
      <div className="container py-5 ">
        <Row className="align-items-center">
          <Col className="col-12 col-lg-5 d-flex d-lg-block justify-content-center">
            <div className="d-flex flex-column flex-lg-row gap-2 align-items-center gap-lg-4">
              <div>
                <img src={footerLogo} alt="Skill Surf Logo" />
              </div>
              <div>
                <p
                  className="mb-1 font-family-class fs-6 text-gray-2 text-center text-lg-start"
                  style={{ fontWeight: 400 }}
                >
                  Empowering Sri Lankans to be industry and life&#8209;ready
                </p>
                <p
                  className="mb-0 font-family-class fs-6 text-gray-3 text-center text-lg-start"
                  style={{ fontWeight: 400 }}
                >
                  &#169; 2019-2024 skillsurf.lk
                </p>
              </div>
            </div>
          </Col>
          <Col className="col-12 col-lg-6 mt-4 mt-lg-0 ">
            <div className="d-flex flex-column align-items-center flex-sm-row justify-content-lg-between justify-content-center gap-3 gap-sm-5">
              <div>
                <p
                  className="mb-1 font-family-class fs-5 text-secondary text-center mb-2"
                  style={{ fontWeight: 700 }}
                >
                  Contact Us
                </p>
                <p className="mb-0 d-flex align-items-center jusitfy-content-center flex-wrap flex-lg-nowrap">
                  <img
                    src={mailIcon}
                    alt="Mail Icon"
                    width={"20px"}
                    height={"15px"}
                    className="me-2"
                  />
                  <a
                    href="mailto:skillcamp@skillsurf.lk"
                    className="font-family-class"
                    style={{ textDecoration: "none" }}
                  >
                  skillcamp@skillsurf.lk
                  </a>
                 <span className="mx-2">|</span>
              
                  <a
                    href="mailto:skillsurfedu@gmail.com"
                    className="font-family-class"
                    style={{ textDecoration: "none" }}
                  >
                    skillsurfedu@gmail.com
                  </a>


                  
                </p>
           
              </div>

              <div className="col-lg-5">
                <div>
                  <p
                    className="mb-1 font-family-class fs-5 text-secondary text-center"
                    style={{ fontWeight: 700 }}
                  >
                    Follow Us
                  </p>
                </div>
                {/* Have to change the URLs */}
                <div className="d-flex gap-3 justify-content-center align-items-start mt-2 flex-wrap">
                  <a href="https://www.facebook.com/SkillSurfEdu/" target="_blank">
                    <img src={fbIcon} alt="Facebook Icon"  />
                  </a>
                  <a href="https://www.instagram.com/skillsurfedu/" target="_blank">
                    <img src={igIcon} alt="Instagram Icon"  />
                  </a>
                  <a href="https://www.linkedin.com/company/skillsurfedu/" target="_blank">
                    <img src={linkedinIcon} alt="LinkedIn Icon"  />
                  </a>
                  <a href="https://twitter.com/SkillSurfEdu" target="_blank">
                    <img src={twitterIcon} alt="Twitter Icon"  />
                  </a>
                  <a href="https://www.youtube.com/@skillsurfedu" target="_blank">
                    <img src={youtubeIcon} alt="Youtube Icon"  />
                  </a>
                  <a href="https://whatsapp.com/channel/0029VaKXY1o1XquXH9CzGv2A" target="_blank">
                    <img src={whatsapp_icon} alt="WhatsApp Icon"  />
                  </a>
                  <a href="https://github.com/SkillSurf" target="_blank">
                    <img src={github} alt="Github Icon"  />
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {props.isFinancialInquiries && (
          <div className="text-center mt-5">
            <hr />
            <Row className="align-items-center row-cols-auto justify-content-center gap-4 pt-4">
              <Col>
                <NavLink
                  to={"/refund-policy"}
                  className={`text-decoration-none font-family-class policy ${
                    props.active === "refund-policy" ? `active` : ``
                  }`}
                >
                  Refund Policy
                </NavLink>
              </Col>
              <Col>
                <NavLink
                  to={"/privacy-policy"}
                  className={`text-decoration-none font-family-class  policy ${
                    props.active === "privacy-policy"
                      ? `font-weight-700`
                      : `font-weight-400`
                  }`}
                >
                  Privacy Policy
                </NavLink>
              </Col>
              <Col>
                <NavLink
                  to={"/terms-and-conditions"}
                  className={`text-decoration-none font-family-class  policy ${
                    props.active === "terms-and-conditions"
                      ? `font-weight-700`
                      : `font-weight-400`
                  }`}
                >
                  Terms and Conditions
                </NavLink>
              </Col>
            </Row>
            <p className="pt-4">
              For all financial inquiries contact us at{" "}
              <a href="mailto:carelfinances@gmail.com">
                carelfinances@gmail.com
              </a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Footer;
