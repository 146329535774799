import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDFYFbkGG8e9yO2LUF_ezLqfZ0Dru4IMzI",
  authDomain: "skill-surf.firebaseapp.com",
  projectId: "skill-surf",
  storageBucket: "skill-surf.appspot.com",
  messagingSenderId: "1050917475593",
  appId: "1:1050917475593:web:0ed84a8304f87c184a6fd8",
};

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
const analytics = getAnalytics(app);
