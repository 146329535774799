import difficulty_level_icon from "./assets/difficulty_level_.svg";
import clock_icon from "./assets/schedule.svg";
import "./CourseCard.scss";
import { Link } from "react-router-dom";
import { DynamicStar } from "react-dynamic-star";

interface cardData {
  cardImage?: any;
  title?: string;
  description?: string;
  difficulty?: string;
  type?: string;
  ratingCount?: number;
  starCount?: number;
  learning?: string;
  prereq?: string;
  conductedBy?: string;
  duration?: string;
  actionProgramDetails?: any;
  actionDownload?: any;
  IsSpecialization?: boolean;
  specialization?: any;
  isLineBreak?: boolean;
  isOneDayWorkShop?: boolean;
  isNewCourse?: boolean;
  isNewWorkshop?: boolean;
  isOverlay?: boolean;
  overlayText?: string;
  buttonText?: string;
  selectedProgramType?: string;
  currentState?: string;
  courseFee?: string;
  discount?: string;
  cardImageAlt?:string;
  isNewIntake?:boolean;
  newIntakeText?:string;
}

const CourseCard = (props: cardData) => {
  return (
    <div>
      <div>
        <div className="card m-3 course-card">
          <div className="row g-0">
            <div className="col-md-4">
              <img
                src={props.cardImage}
                className="img-fluid rounded-start card-image"
                alt={props.cardImageAlt}
                style={{ objectFit: "cover" }}
              />
              {props.isOverlay ? (
                <div className="card-img-overlay">
                  <p
                    className=" fs-6 p-1  text-white text-center"
                    style={{
                      marginBottom: "0.5em",
                      background: "#02DF18",
                      width: "4rem",
                      borderRadius: "10px",
                    }}
                  >
                    {props.overlayText}
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="col-md-4 " style={{ zIndex: "99" }}>
              <div className="card-body m-1">
                <div className="col-lg-12 d-flex align-items-center  justify-content-start flex-wrap ">
                  <div
                    className={`course-type-tag fs-7 px-3 mt-2 ${
                      props.type === "Workshop" ? "bg-alt" : "bg-primary"
                    }`}
                    style={{ marginRight: "2px" }}
                  >
                    {props.type}
                  </div>
                  <div className="course-difficulty-tag text-nowrap text-gray mx-lg-3 w-lg-25 mt-2">
                    <img
                      src={difficulty_level_icon}
                      alt={`${props.title} course`}
                      style={{ marginRight: "3px" }}
                    />
                    {props.difficulty}
                  </div>
                  <div className="course-difficulty-tag text-nowrap text-gray mx-lg-0 mt-2">
                    <img
                      src={clock_icon}
                      style={{ marginRight: "2px" }}
                      alt=""
                    />
                    {props.duration}
                  </div>
                </div>
                <Link to={props.actionProgramDetails} className="text-decoration-none title-link"><h5 className="fs-5 text-secondary mt-2">{props.title}</h5></Link>
                {props.IsSpecialization ? (
                  <p
                    className="text-gray fs-6"
                    style={{ marginBottom: "0.5em" }}
                  >
                    Specialization:{" "}
                    <span
                      className="bg-secondary p-1 px-3 rounded text-white m-lg-2 text-nowrap "
                      style={{ fontSize: "0.9em" }}
                    >
                      {props.specialization}
                    </span>{" "}
                  </p>
                ) : (
                  <p
                    className=" text-nowrap text-gray fs-6"
                    style={{ marginBottom: "0.5em" }}
                  >
                    {" "}
                  </p>
                )}

                {props.isOneDayWorkShop ? (
                  <p
                    className="text-gray fs-6 my-3"
                    style={{ marginBottom: "0.5em" }}
                  >
                    <span
                      className="p-2 px-3 rounded text-white  text-nowrap gradient-label"
                      style={{ fontSize: "0.9em" }}
                    >
                      One Day Workshop
                    </span>{" "}
                  </p>
                ) : (
                  <p
                    className=" text-nowrap text-gray fs-6"
                    style={{ marginBottom: "0.5em" }}
                  >
                    {" "}
                  </p>
                )}
                <p className="text-gray fs-6">{props.description}</p>
                <p
                  className="text-primary m-0 border border-primary rounded border-2  px-1"
                  style={{
                    fontWeight: "400",
                    width: "fit-content",
                    paddingTop: "1px",
                    fontSize: "0.9em",
                  }}
                >
                  {props.currentState}
                </p>

               {/*  {props.courseFee && (
                  <div>
                    {" "}
                    <p className="fs-6 mt-3">
                      Course Fee:{" "}
                      <span
                        className="text-primary "
                        style={{ fontWeight: "500" }}
                      >
                        {"Rs. "}
                        {props.courseFee}
                      </span>
                      {props.discount && (
                        <span
                          className="bg-yellow p-1 px-3 rounded text-white m-lg-2 text-nowrap "
                          style={{ fontSize: "0.9em" }}
                        >
                          {props.discount} Discount
                        </span>
                      )}{" "}
                    </p>
                    <p>
                      For all financial inquiries contact us at{" "}
                      <a href="mailto:carelfinances@gmail.com">
                        carelfinances@gmail.com
                      </a>
                    </p>
                  </div>
                )} */}
              </div>

              <hr className="hr-card-m" />
            </div>

            <div className="col-md-4 ">
              <div className="card-body">
                <div className="row mt-2">
                  <p className="sub-text fs-6 text-gray">Prerequisites</p>
                  <p className="sub-description text-gray-2  fs-6">
                    {props.prereq}
                  </p>
                </div>
                <div className="row mt-2">
                  <p className="sub-text fs-6 text-gray">Conducted By</p>
                  <p className="sub-description text-gray-2  fs-6">
                    {props.conductedBy}
                  </p>
                </div>
                {props.isNewCourse ? (
                  <div className="row my-3">
                    <p
                      className="text-gray fs-6"
                      style={{ marginBottom: "0.5em" }}
                    >
                      <span
                        className="bg-secondary p-1 px-3 rounded text-white  "
                        style={{ fontSize: "0.9em" }}
                      >
                        
                        New Course
                      </span>{" "}
                    </p>
                  </div>
                ) : null}

                {props.isNewIntake && (
                    <div className="row my-3">
                    <p
                      className="text-gray fs-6"
                      style={{ marginBottom: "0.5em" }}
                    >
                      <span
                        className="bg-secondary p-1 px-3 rounded text-white  "
                        style={{ fontSize: "0.9em" }}
                      >
                        
                       {props.newIntakeText}
                      </span>{" "}
                    </p>
                  </div>
                )}

                {props.isNewWorkshop ? (
                  <div className="row my-3">
                    <p
                      className="text-gray fs-6"
                      style={{ marginBottom: "0.5em" }}
                    >
                      <span
                        className="bg-secondary p-1 px-3 rounded text-white  "
                        style={{ fontSize: "0.9em" }}
                      >
                        New Workshop
                      </span>{" "}
                    </p>
                  </div>
                ) : null}

                {props.ratingCount === 0 ? (
                  <div className="row">
                    <p className="text-muted fs-6 text-gray-2 mt-5">Not rated yet</p>
                    <div className="col d-flex align-items-start justify-content-start">
                      <DynamicStar
                        rating={props.starCount}
                        width={20}
                        height={20}
                        emptyStarColor={"#DBDBDB"}
                      />
                    </div>
                    <p className="card-text">
                      <small className="text-muted fs-6 text-gray-2">
                        ({props.starCount}) {props.ratingCount} Reviews
                      </small>
                    </p>
                  </div>
                ) : (
                  <div className="row mt-3">
                    <div className="d-flex col-md-12  justify-content-start">
                      <div className="col d-flex align-items-start justify-content-start">
                        <DynamicStar
                          rating={props.starCount}
                          width={20}
                          height={20}
                          emptyStarColor={"#DBDBDB"}
                        />
                      </div>
                    </div>
                    <div className="d-flex col-md-12  justify-content-start ">
                      <p className="card-text">
                        <small className="text-muted fs-6 text-gray-2">
                          ({props.starCount}) {props.ratingCount} Reviews
                        </small>
                      </p>
                    </div>
                    <div className="my-0 my-lg-4"></div>
                  </div>
                )}

                <div className="row mt-3 px-0 px-sm-0 ">
                  <Link
                    to={props.actionProgramDetails}
                    className="d-flex align-items-center justify-content-center"
                    style={{
                      textDecoration: "none",
                      pointerEvents:
                        props.actionProgramDetails === "/" ? "none" : "all",
                    }}
                  >
                    <button
                      className="gradient-button fs-6"
                      style={{
                        width: "100%",
                        zIndex: "99",
                        display:
                          props.actionProgramDetails === "/" ? "none" : "",
                      }}
                    >
                      {props.buttonText?.toLocaleUpperCase()}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
